<template>
  <v-container
    class="px-lg-10 mx-0 background-color: white"
    style="border: 1px solid grey"
  >
    <v-row>
      <v-col class="text-right">
        <v-btn
          color="orange"
          dark
          class="mb-2"
          @click="cancelButtonClick"
        >
          KEMBALI
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col
        cols="12"
        sm="6"
        md="2"
      >
        Nama Badan Usaha
      </v-col>
      <v-col
        cols="12"
        sm="6"
        md="4"
      >
        {{ dataCustomerCompany.name_legal }}
      </v-col>
    </v-row>

    <v-row>
      <v-col
        cols="12"
        sm="6"
        md="2"
      >
        Nama Merek
      </v-col>
      <v-col
        cols="12"
        sm="6"
        md="4"
      >
        {{ dataCustomerCompany.name }}
      </v-col>
    </v-row>

    <v-row>
      <v-col
        cols="12"
        sm="6"
        md="2"
      >
        Nama Pemilik
      </v-col>
      <v-col
        cols="12"
        sm="6"
        md="4"
      >
        {{ dataCustomerCompany.owner }}
      </v-col>
    </v-row>

    <v-row>
      <v-col
        cols="12"
        sm="6"
        md="2"
      >
        Telepon
      </v-col>
      <v-col
        cols="12"
        sm="6"
        md="4"
      >
        {{ dataCustomerCompany.phone }}
      </v-col>
    </v-row>

    <v-row>
      <v-col
        cols="12"
        sm="6"
        md="2"
      >
        Alamat
      </v-col>
      <v-col
        cols="12"
        sm="6"
        md="4"
      >
        {{ fullAddress }}
      </v-col>
    </v-row>

    <v-row>
      <v-col
        cols="12"
        sm="6"
        md="2"
      >
        Status
      </v-col>
      <v-col
        cols="12"
        sm="6"
        md="2"
      >
        <v-select
          :value="selectedStatus"
          class="mx-0 my-0 px-0 py-0"
          :items="statusItems"
          item-text="text"
          item-value="value"
          @change="changeStatus"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import axios from 'axios'
  axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL
  axios.defaults.headers.post['x-api-key'] = localStorage.getItem('token')

  export default {
    data: () => ({
      dataCustomerCompany: {
        hash: '',
        status: '',
        status_string: '',
        name: '',
        director_name: '',
        phone: '',
        akot_string: '',
        apro_string: '',
        verified: '',
        owner: '',
      },
      statusItems: [
        { text: 'Aktif', value: 1 },
        { text: 'Non-Aktif', value: 2 },
      ],
    }),

    computed: {
      fullAddress () {
        return this.dataCustomerCompany.akot_string + ', ' + this.dataCustomerCompany.apro_string
      },
      selectedStatus () {
        return parseInt(this.dataCustomerCompany.status)
      },
    },

    watch: {
    },

    created () {
      this.initialize(this.$route.params.id)
    },

    methods: {
      initialize (id) {
        const vm = this

        const requestBody = {
          cust_com_hash: id,
        }

        axios.post('/t/customer/company/detail', this.$qs.stringify(requestBody)).then((res) => {
          if (res.data.status === 200) {
            vm.dataCustomerCompany = res.data.data.detail
          }
        }).catch((e) => {
        })
      },

      changeStatus (val) {
        const requestBody = {
          cust_comp_hash: this.dataCustomerCompany.hash,
          new_status: val,
        }

        axios.post('/t/customer/company/mod/status', requestBody).then((res) => {
          if (res.data.status === 200) {
            this.$toast.success(res.data.message)
          }
        }).catch((e) => {
          e.response.data.error_type === 'array' ? this.$toast.error(e.response.data.error.reason) : this.$toast.error(e.response.data.error)
        })
      },

      cancelButtonClick () {
        this.$router.push({ name: 'CustomerCompany' })
      },
    },
  }
</script>
